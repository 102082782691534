import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Navigation, Route, Screen, Link, glide } from "react-tiger-transition";
import DetailFollowUp from '../listFollowsUps/DetailFollowUp';
import ListFollowsUps from '../listFollowsUps/ListFollowsUps';
import Login from '../login/Login';
import Map from '../map/Map';
import Home from '../login/Home';
glide({
    name: 'glide-left'
});
glide({
    name: 'glide-right',
    direction: 'right'
});

document.getElementById("root").style.height = "100%";
class Routes extends Component {
    render() {
        return (
            <Router>
                {/* BrowserRouter from react-router-dom */}

                {/* Context provider for transitions */}
                <Navigation>
                    {/* Use Route the same way you use
            react-router Route with children */}
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/listado-seguimientos/:token">
                        <ListFollowsUps />
                    </Route>
                    <Route exact path="/listado-seguimientos-detail/:id">
                        <DetailFollowUp />
                    </Route>
                    <Route exact path="/mapa">
                        <Map />
                    </Route>
                    {/* <Route exact path="/">
                        <Login />
                    </Route> */}

                </Navigation>
            </Router>
        )
    }
}

export default Routes;
