import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, glide } from "react-tiger-transition"
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';

const styles = theme => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: "100vh",
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },

});
class Map extends Component {
    state = {
        url: `https://webapp.4gflota.com/checktoken/${this.props.location.state.dataObj.value.tokenToRequestMap}/${this.props.location.state.dataObj.value.email}`
    }
    render() {
        const { classes } = this.props;
        // console.log(this.props);
        return (
            <div>
                <div>
                    <List style={{paddingBottom:0}} className={classes.root} subheader={<li />}>

                        <li  className={classes.listSection}>
                            <ul className={classes.ul}>
                                <ListItem style={{ background: "#bf2349", width:"100%", color: "#fff" }} button component={Link} to={{
                                    pathname: `/listado-seguimientos/${this.props.location.state.token}`,
                                    state: this.props.location.state
                                }}><IconButton>
                                        <ArrowBackIosIcon style={{ color: "#fff" }} />
                                    </IconButton>Mapa</ListItem>
                                {/* {[0, 1, 2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((item, index) => ( */}

                            </ul>
                        </li>
                        {/* <div style={{ verticalAlign: "middle", background: "#ededed", padding: 22, fontWeight: "bold", fontSize: 20 }}>
                            <MapOutlinedIcon style={{ verticalAlign: "middle", marginBottom: 2, marginRight: 10 }} />
                            Mapa
                        </div> */}
                    </List>


                </div>

                <div style={{ height: "90vh", width: "100vw" }}>
                    <iframe style={{ border: "none", height: "100%", width: "100%", overflow: "hidden" }} height="100%" width="100%" src={this.state.url} >

                    </iframe>
                </div>
            </div>

        )
    }
}
export default withRouter(withStyles(styles, { withTheme: true })(Map));
