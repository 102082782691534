import React, { Component } from 'react';
import './Login.css';
import Logo from './img/4gflota_logo_new.png';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import {Link} from 'react-router-dom';



const styles = theme => ({
    root: {
        height: "300px",
        position: "relative",
        '& > *': {
            margin: "15px auto",
            width: "90%",
            height: theme.spacing(30),
        },
    },
    margin: {
        // margin: theme.spacing(1),
        padding: "15px"
    },
    center: {
        margin: 0,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    dialog: {
        [theme.breakpoints.down('sm')]: {
        },
    }

});

class Login extends Component {

    state = {
        isOpenDialogLogin: false,
        // valueEmail: localStorage.getItem('email') !== null ? localStorage.getItem('email') : "",
        valueEmail: this.props.history.location.state !== undefined ? this.props.history.location.state.email : localStorage.getItem('email'),
        isChecked: localStorage.getItem('isChecked') === "true" ? true : false,
        isDisableButton: true,
        valueCode: localStorage.getItem('token') !== null ? localStorage.getItem('token') : ""
    }

    componentDidMount(){
        
    }
    
    validateForm() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailCorrectFormatBool = re.test(String(this.state.valueEmail).toLowerCase());
        // console.log(emailCorrectFormatBool);
        if (this.state.valueEmail !== "" && emailCorrectFormatBool && this.state.valueCode !== "") {
            return true;
        } else {
            return false
        }
        // return (this.state.valueEmail.length > 0);
    }
    async handleOpenDialogLogin() {
        let isChecked = localStorage.getItem('isChecked');
        let emailStorage = localStorage.getItem('email');
        // console.log(emailStorage)
        if (emailStorage !== this.state.valueEmail) {
            localStorage.removeItem('email');
            localStorage.removeItem('token');
        } 
        if (this.state.isChecked) {
            localStorage.setItem('isChecked', true);
            localStorage.setItem('email', this.state.valueEmail);
            localStorage.setItem('token', this.state.valueCode);
            localStorage.setItem('emailForMap', this.state.valueEmail);
        }else {
            localStorage.removeItem('email');
            localStorage.removeItem('token');
            localStorage.setItem('isChecked', false);
            localStorage.setItem('emailForMap', this.state.valueEmail);
        }
        // let token = localStorage.getItem('token');
        // if (localStorage.getItem('email') !== null) {
            
        // } 
        let isValidToken = await this.checkAccessControlToken();
        if (isValidToken) {
            this.props.history.push({
                pathname: `/listado-seguimientos/${this.state.valueCode}`,
                // state: {
                //     dataObj: {
                //         value: value
                //     },
                //     token: token
                // }
            })
        }
        
        // else {
        //     this.setState({
        //         isOpenDialogLogin: true
        //     }, async () => {
        //         this.getAccessControlToken();
        //     });
        // }

    }

    handleAcceptDialogLogin() {
        this.setState({
            isOpenDialogLogin: false
        });
    }

    handleCloseDialogLogin() {
        this.setState({
            isOpenDialogLogin: false
        });
    }

    handleOnChangeValueEmail(e) {
        this.setState({
            valueEmail: e.target.value
        });
    }

    handleOnChangeValueCode(e) {
        this.setState({
            valueCode: e.target.value
        })
    }

    async checkAccessControlToken() {
        let response = {};
        let email = this.state.valueEmail;
        
        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        

        let responseServices = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Check?token=${this.state.valueCode}&email=${email}`, settings);

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                    if (!response) {
                        this.setState({
                            isOpenDialogLogin: true
                        })
                    }
                    return response;
                } catch (error) {
                    response = null;
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            // this.goToPageErr();
            return;
        }
    }

    async getAccessControlToken() {
        let response = {};
        let email = this.state.valueEmail;
        const settings = {
            method: 'GET',
            headers: {
                'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        let responseServices = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Login?email=${email}`, settings);

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                    if (this.state.isChecked) {
                        localStorage.setItem('isChecked', this.state.isChecked);
                        localStorage.setItem('email', this.state.valueEmail);
                    } else {
                        localStorage.removeItem('isChecked');
                    }
                    return response;
                } catch (error) {
                    response = null;
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            // this.goToPageErr();
            return;
        }
    }

    handleOnChangeSaveChecked(e) {
        // console.log(e.target.checked);
        if (e.target.checked === false) {
            localStorage.setItem('isChecked', false);
            localStorage.removeItem('email');
            localStorage.removeItem('token');
        } 
        this.setState({
            isChecked: e.target.checked
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ background: "#d9dadb", height: "100vh" }}>
                {/* <div className="curved">
                    <h1>Inicio de sesión</h1>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 318"><path fill="#fff" fill-opacity="1" d="M0,32L40,64C80,96,160,160,240,176C320,192,400,160,480,176C560,192,640,256,720,282.7C800,309,880,299,960,256C1040,213,1120,139,1200,112C1280,85,1360,107,1400,117.3L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>
                </div> */}
                <div className="main-form-container">


                    <Paper style={{ background: "#d9dadb" }} elevation={0}>
                        <div>
                            <Grid container justify="center" spacing={1} alignItems="flex-end">
                                <Grid item xs={6}>
                                    <img style={{ width: "180px", marginBottom: "20px" }} src={Logo} alt="logo4Gflota"></img>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <TextField style={{width:"90%"}} id="input-with-icon-grid" label="Introduzca su Email" /> */}
                                    <InputBase
                                        style={{ width: "90%", background: "#fff", marginLeft: "4%", borderRadius: "4px" }}
                                        
                                        value={this.state.valueEmail}
                                        onChange={this.handleOnChangeValueEmail.bind(this)}
                                        className={classes.margin}
                                        
                                        error={true}
                                        placeholder="Introduzca su email"
                                        autoComplete={"false"}
                                    />

                                    <InputBase
                                        style={{ width: "90%", background: "#fff", marginLeft: "4%", borderRadius: "4px", marginTop: 15 }}
                                        // label="Introduzca su Email"
                                        value={this.state.valueCode}
                                        onChange={this.handleOnChangeValueCode.bind(this)}
                                        className={classes.margin}
                                        // InputProps={{
                                        //     startAdornment: <InputAdornment position="start"><EmailIcon color="secondary" /></InputAdornment>,
                                        // }}
                                        error={true}
                                        placeholder="Introduzca su código"
                                        type="password"
                                        autoComplete={"false"}
                                    />
                                    <div className="hr-theme-slash-2">
                                        <div className="hr-line"></div>
                                        <div className="hr-icon">
                                            <Typography style={{ textAlign: "center", marginTop: 12 }}>
                                                <Link style={{ textDecoration: "none" }} to={{pathname:"/", fromLogin: true}}>
                                                    Obtener código
                                                </Link>
                                            </Typography>
                                        </div>
                                        <div className="hr-line"></div>
                                    </div>
                                    <div style={{ width: "90%", padding: "15px" }}>
                                        <Button disabled={!this.validateForm()} onClick={this.handleOpenDialogLogin.bind(this)} style={{ height: "60px", width: "100%", padding: "10px", color: "#fff", }} color="secondary" variant="contained">
                                            Acceder
                                        </Button>
                                    </div>


                                </Grid>

                                {/* <Grid item xs={10}>
                                    <Divider />
                                </Grid> */}
                                <Grid item xs={6}>

                                    <div style={{ textAlign: "center" }}>
                                        <FormControlLabel
                                            value={false}
                                            control={<Checkbox onChange={this.handleOnChangeSaveChecked.bind(this)} checked={this.state.isChecked} color="secondary" />}
                                            label="Recordar"
                                            labelPlacement="end"
                                        />
                                    </div>
                                </Grid>

                            </Grid>
                        </div>
                    </Paper>
                </div>
                {/* <div className="curvedBottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,32L40,64C80,96,160,160,240,176C320,192,400,160,480,176C560,192,640,256,720,282.7C800,309,880,299,960,256C1040,213,1120,139,1200,112C1280,85,1360,107,1400,117.3L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>
                </div> */}
                <Dialog
                    fullScreen={false}
                    open={this.state.isOpenDialogLogin}
                    onClose={this.handleCloseDialogLogin}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Aviso"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Su código ya no está activo.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        
                        <Button
                            onClick={this.handleAcceptDialogLogin.bind(this)}
                            color="secondary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(Login));
