import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import PullToRefresh from 'rmc-pull-to-refresh';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../config';

import '../../index.css';



const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: "100vh",
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },

});

class ListFollowsUps extends Component {

    state = {
        listFollowsUps: [],
        isLoading: true,
        refreshing: false
    }

    async componentDidMount() {
        // let params = (new URL(window.location)).searchParams;

        // let token = params.get('token');
        // console.log(token);

        let response = await this.getAccessControlListFollows();
        // console.log(response);
        this.setState({
            listFollowsUps: response,

        }, () => {
            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            }, 1500);
        });
    }

    async getAccessControlListFollows() {
        let response = [];
        let url = window.location.href;

        let newUrl = url.split("/");

        let token = newUrl[4];

        

        const settings = {
            method: 'GET',
            headers: {
                // 'accessToken': this.props.location.state !== undefined ? this.props.location.state.AccessToken : this.state.accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        // IsActive(bool), Email, Observations
        // https://4yahqnkrsh.execute-api.eu-west-1.amazonaws.com/Prod/api/AccessControl/Transport/Tracing?token=396E9947B85F80FB68A37570986B0904
        let responseServices = await fetch(`${config.apiGateway.URL}AccessControl/Transport/Tracing?token=${token}`, settings);
        // let responseServices = await fetch(config.apiGateway.URL + "/AccessControl?IsActive=false&Email=''&Observations=", settings);

        if (responseServices.ok) {
            if (responseServices !== null || responseServices !== undefined) {
                try {
                    response = await responseServices.json();
                    let listFollowsUps = [];

                    response.forEach(element => {
                        let newObject = {
                            title: element.email,
                            startDate: element.startDate,
                            endDate: element.endDate,
                            tokenToRequestMap: element.id,
                            email: localStorage.getItem("emailForMap")
                        }
                        listFollowsUps.push(newObject);
                    });
                    // let isChecked = localStorage.getItem('isChecked');
                    // if (isChecked) {
                    //     localStorage.setItem('token', token);
                    // }
                    return listFollowsUps;
                } catch (error) {
                    response = null;
                }
            }
            // console.log(response);
            // response = null;


        } else {
            // alert("HTTP-Error: " + responseServices.status);
            // this.goToPageErr();
            return;
        }
    }

    viewFollowUp(value) {
        // console.log(value);
        // let copyListFollowsUps = [...this.state.listFollowsUps];

        // let result = listFollowsUps.filter(element => element.)
        let startDate = this.createDate(value.startDate);
        let endDate = this.createDate(value.endDate);
        let actualDate = new Date();
        let url = window.location.href;
        let newUrl = url.split("/");
        let token = newUrl[4];

        // console.log(actualDate)

        if (actualDate > startDate && actualDate < endDate) {
            // console.log(true);
            this.props.history.push({
                pathname: `/mapa`,
                state: {
                    dataObj: {
                        value: value
                    },
                    token: token
                }
            })
        } else {
            // console.log(false);
            this.props.history.push({
                pathname: `/listado-seguimientos-detail/${value.title}`,
                state: {
                    dataObj: {
                        value: value
                    },
                    token: token
                },

            })
        }
    }

    handleOnClickLogOut() {
        if (localStorage.getItem('email') !== null && localStorage.getItem('token') !== null) {
            this.props.history.push({
                pathname: `/login`,
    
            })
        } else {
            this.props.history.push({
                pathname: `/`,
    
            })
        }
        
    }

    createDate(date) {
        let newDate = new Date(date);
        // console.log(newDate);
        return newDate;
    }

    getAccessControlListFollowsRefresh() {
        this.setState({
            refreshing: true
        }, async () => {
            let result = await this.getAccessControlListFollows();
            setTimeout(() => {
                this.setState({
                    listFollowsUps: result,
                    refreshing: false
                })
            }, 1500);
           

        })
    }

    render() {
        const { classes } = this.props;

        return (
            <div style={{ margin: "auto", height: "100%" }}>
                <PullToRefresh
                    style={{ height: "100%", overflowY: 'auto', overflowX: "hidden", textAlign:"center" }}
                    direction="down"
                    refreshing={this.state.refreshing}
                    onRefresh={this.getAccessControlListFollowsRefresh.bind(this)}
                    indicator={
                        {
                            activate: <ArrowDownwardOutlinedIcon className="rotate" />,
                            deactivate: <div></div>,
                            release: <div><CircularProgress color="secondary" /></div>,
                            finish: <div></div>
                        }
                    }

                >
                    <List className={classes.root} subheader={<li />}>

                        <li className={classes.listSection}>
                            <ul className={classes.ul}>
                                <ListItem style={{ padding: 22, background: "#bf2349", color: "#fff" }}>

                                    {/* <IconButton>
                                    <ArrowBackIosIcon />
                                </IconButton> */}
                                    Listado de Seguimientos
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={this.handleOnClickLogOut.bind(this)} edge="end" aria-label="delete">
                                            <ExitToAppIcon style={{ color: "#fff" }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                {this.state.isLoading ?
                                    <LinearProgress color="secondary" />
                                    :
                                    <div style={{ marginTop: 0 }}>
                                        {this.state.listFollowsUps !== undefined ? this.state.listFollowsUps.map((item, index) => (
                                            // <ListItem key={`item-${index}-${item}`}>
                                            //     <ListItemText primary={`Item ${item}`} />
                                            // </ListItem>
                                            <div key={index}>
                                                <ListItem key={index} onClick={this.viewFollowUp.bind(this, item)} button >

                                                    <ListItemText
                                                        primary={`${item.title}`}
                                                    // secondary={'Secondary text'}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="delete">
                                                            <ArrowForwardIosOutlinedIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />

                                            </div>
                                        ))
                                            : 
                                            <div>Actualmente no dispone de ningún seguimiento dado de alta.</div>    
                                        }
                                    </div>

                                }


                            </ul>
                        </li>

                    </List>

                </PullToRefresh>

            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(ListFollowsUps));
