import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Link, glide } from "react-tiger-transition"
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';


const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: "100vh",
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },

});

class DetailFollowUp extends Component {

    state = {
        startDate: "",
        endDate: ""
    }

    // componentDidMount(){
    //     let startDate = this.formatDateToString(this.props.location.dataObj.startDate);
    //     let endDate = this.formatDateToString(this.props.location.dataObj.endDate);

    //     this.setState({
    //         startDate: startDate,
    //         endDate: endDate
    //     })
    // }

    formatDateToString(date) {
        let time = new Date(date);
        let day = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());
        let month = ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1));
        let year = time.getFullYear();
        let hours = time.getHours();
        let minutes = time.getMinutes();

        return year.toString() + month.toString() + day.toString() + " " + hours + ":" + minutes;

    }

    render() {
        const { classes } = this.props;
        // console.log(this.props.location.state);
        return (
            <div>
                <List className={classes.root} subheader={<li />}>

                    <li className={classes.listSection}>
                        <ul className={classes.ul}>
                            <ListItem style={{ background: "#bf2349", color: "#fff" }} button component={Link} to={{
                                pathname: `/listado-seguimientos/${this.props.location.state.token}`,
                                state: this.props.location.state
                            }}><IconButton>
                                    <ArrowBackIosIcon style={{color:"#fff"}} />
                                </IconButton> {`Detalles del seguimiento - ${this.props.location.state.dataObj.value.title}`}</ListItem>
                            {/* {[0, 1, 2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((item, index) => ( */}

                        </ul>
                    </li>
                    {/* <div style={{ verticalAlign: "middle", background: "#ededed", padding: 22, fontWeight: "bold", fontSize: 20 }}>
                        <CommentOutlinedIcon style={{ verticalAlign: "middle", marginBottom: 2, marginRight: 10 }} />
                        {`Detalles del seguimiento - ${this.props.location.state.dataObj.value.title}`}
                    </div> */}
                </List>

                <Card elevation={0} style={{ paddingTop:0, margin:"auto" }}>
                    <CardContent>
                        <Grid container justify="center" spacing={1} alignItems="center">
                            <Grid item xs={2}>
                                <Typography style={{marginLeft:"60%"}}>
                                    Inicio:
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography style={{textAlign:"center"}}>
                                    {this.props.location.state.dataObj.value.startDate}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{marginLeft:"60%"}}>
                                    Fin:
                                </Typography>
                            </Grid>

                            <Grid item xs={10}>
                                <Typography style={{textAlign:"center"}}>
                                    {this.props.location.state.dataObj.value.endDate}
                                </Typography>
                            </Grid>


                        </Grid>

                    </CardContent>

                </Card>
            </div>
        )
    }
}

export default withRouter(withStyles(styles, { withTheme: true })(DetailFollowUp));
