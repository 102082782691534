import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "react-tiger-transition/styles/main.min.css";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Routes from './components/routes/Routes';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const theme = createMuiTheme({
  palette: {
       secondary: {
        // This is green.A700 as hex.
        main: '#bf2349',
      },
     }
   });
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <ThemeProvider theme={theme}><Routes /></ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
